import style from './assets/scss/accueil.module.scss';
import styleModal from '../../components/modal/modal.module.scss';
import { Autocomplete, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { Invest } from '../../services/object/Invest';
import { Category, CategoryIT } from '../../services/object/Category';
import styled from 'styled-components';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Salepoint } from '../../services/object/Salepoint';
import { Link } from 'react-router-dom';
import { Univers } from '../../services/object/Univers';
import Cookies from 'universal-cookie';
import RadioOnly from '../../components/input/RadioOnly';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NumericFormatCustom } from '../../components/input/NumericInput';
import { fakeObjectRadioBudget, fakeObjectRadioRoi } from '../../services/object/FakeObject';
import { User } from '../../services/object/User';
import Service_Api from '../../services/Api';
import { Comment } from '../../services/object/Comment';
import { jsDateToLocalFr, sqlToJsDate } from '../../services/tools/translateDate';
import { useUser } from '../../services/auth/useUser';
import DeleteIcon from '@mui/icons-material/Delete';
interface SalepointPopupChildrensProps {
    errorMessages: Record<string, string>,
    formRefInvest: React.RefObject<HTMLFormElement>,
    invest: Invest|undefined;
    TOCategory: Category[];
    TOSalepoint: Salepoint[];
    TOUnivers: Univers[];
    universUid: string;
    categoryUid: string;
    salepointLabel: string[];
    setSalepointLabel: React.Dispatch<React.SetStateAction<string[]>>
    handleFormInvestChange: (property: string, value: string | FileList | null |number | boolean ) => void
    TFilePath : string[];
    admin: boolean;
    disabled: boolean;
    TOComment:Comment[];
    newComment: string;
    setNewComment: React.Dispatch<React.SetStateAction<string>>;
  }
    const FormPopup = ({errorMessages, 
                        formRefInvest, 
                        invest, 
                        categoryUid,
                        salepointLabel,
                        setSalepointLabel,
                        TOCategory,
                        TOSalepoint,
                        handleFormInvestChange,
                        TFilePath,
                        admin,
                        disabled,
                        TOUnivers,
                        universUid,
                        TOComment,
                        newComment,
                        setNewComment} :SalepointPopupChildrensProps) => {
                            

  const [localFormValues, setLocalFormValues] = useState({
                                                            id : 0,
                                                            uid : "",
                                                            name : "",
                                                            price : 0,
                                                            comment : newComment,
                                                            commentState : "",
                                                            userUid : "",
                                                            state : "",
                                                            validPDG : "",
                                                            validDaf : "",
                                                            budget : false,
                                                            budgetType : 0,
                                                            roi : 0,
                                                            userNumber : "",
                                                            userName : "",
                                                            userNameleave : "",
                                                        })     
  const [userValid, setUserValid] = useState<User[]>()           
                                                            
  const { user } = useUser();
  const Api = Service_Api();
  useEffect(() => {
    if (invest) {
        setLocalFormValues({
                id : invest?.id ?? 0,
                uid : invest?.uid ?? "",
                name : invest?.name ?? "",
                price : invest?.price ?? 0,
                comment : "",
                commentState : "",
                userUid : invest?.userUid ?? "",
                state : invest?.state ?? "",
                validPDG : invest?.validPDG ?? "",
                validDaf : invest?.validDaf ?? "",
                budget : invest?.budget ?? false,
                budgetType : invest?.budgetType ?? 0,
                roi : invest?.roi ?? 0,
                userNumber : invest?.userNumber ?? "",
                userName : invest?.userName ?? "",
                userNameleave : invest?.userNameleave ?? "",
        });
        if(invest?.state === "En attente" || invest?.state  === "En validation"){
            let getUserValid = async() => {
                let Tsalepoint = (TOSalepoint.filter((salepoint : Salepoint) => { return salepointLabel.indexOf(salepoint.name) > -1})).map((salepoint :Salepoint) => {return salepoint.uid})
                const response = await Api.post("user/getUserValid", {investUid : invest?.uid, TsalepointUid: Tsalepoint})
                if(response?.success){
                    setUserValid(response?.data)
                }
            }
            getUserValid();
        }
    }
  }, [invest]) 
  
  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if(name === "comment")
        setNewComment(value)
    handleFormInvestChange(name, value);
  }
  
    const handleChangSalepoint= (event: SyntheticEvent<Element, Event>, value: Salepoint[]) => {
        setSalepointLabel(value.map((salepoint)=> (salepoint.name)));
      };
    const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = e.target;
        setLocalFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }
    const VisuallyHiddenInput = styled('input')({
        clip: 'rect(0 0 0 0)',
        clipPath: 'inset(50%)',
        height: 1,
        overflow: 'hidden',
        position: 'absolute',
        bottom: 0,
        left: 0,
        whiteSpace: 'nowrap',
        width: 1,
      });

    //event change on radio input
    const onChangeRadio = (budgetType: string) => {
        //trigger handleFormChange
        handleFormInvestChange("budgetType", budgetType)
    };
    //event change on radio input
    const onChangeRadioUser = (userMove: string) => {
        //trigger handleFormChange
        handleFormInvestChange("userMove", userMove)
    };
    const stateInfo = () => {
        let comment = ""
        let title = "Statut de la demande : "
        let p = ""
        let display = true;
        let chip;
        if(invest?.commentState !== "" && invest?.state !== "En attente"){
            comment = "Raison: " + invest?.commentState || ""
        }

        if(invest?.state === "Informations"){
            p = (invest?.userAsk || "Inconnu") +  " demande plus d'information avant d'investir";
            chip = <Chip label={"Information"} color="info" variant="outlined"/>
        }else if(invest?.state === "Refusée"){
            p = (invest?.userAsk || "Inconnu") + " a rejeté cette demande d'investissement";
            chip =<Chip label={"Refusée"} color="error" variant="outlined"/>
        }else if(invest?.state === "En attente"){
            p = "Ces personnes étudient la demande d'investissement:";
            chip = <Chip label={"En attente"} color="warning" variant="outlined"/>;
        }else if(invest?.state === "En validation"){
            p = "Ces personnes étudient la demande d'investissement:";
            chip = <Chip label={"En validation"} color="secondary" variant="outlined"/>
        }else if(invest?.state === "Validée"){
            p = "La demande a été validée par:";
            chip = <Chip label={"Validée"} color="success" variant="outlined"/>
        }else{
            display = false;
        }
        if(display){
            return <div className={styleModal.infoContent}>
                <h2 className={styleModal.subtitle}>{title}{chip}</h2>
                <p>{p}</p>
                { 
                    (invest?.state !== "Validée") ?
                            userValid?.map((user : User, index: number)=> (
                                <p>{ user.firstname +' '+user.lastname}</p>
                            ))
                        || 
                            <p>{comment}</p>
                    :
                        <>
                            <p>{invest?.validDaf}</p>
                            <p>{invest?.validPDG}</p>
                        </>
                }
                
            </div>
        }else{
            return <></>   
        }
    }
    
    const removeAllFileByInvest = (investUid :string|undefined) => {
        if(investUid){
            Api.del("file/deleteAllFileByInvestUid", {investUid : invest?.uid})
        }
        handleFormInvestChange("file", null);
    }
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (<form className={style.uiform} ref={formRefInvest}>
                {stateInfo()}
                <h2  className={styleModal.subtitle}>Les informations générales :</h2>
                <div className={style.contentText}>
                    <TextField fullWidth label="Sujet"  
                        size="medium"
                        value={localFormValues.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.name}
                        helperText={errorMessages.name}
                        disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}/>
                    <FormControl>
                        <InputLabel className={style.selectLabel}>Catégorie</InputLabel>
                        <Select
                            required
                            name='category'
                            size="medium"
                            input={<OutlinedInput label="Catégorie" />}
                            value={categoryUid}
                            onChange={(e) => handleFormInvestChange('categoryUid', e.target.value)}
                            error={!!errorMessages.category}
                            disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}>
                                {TOCategory.map((category : Category) => (
                                    <MenuItem key={category.uid} value={category.uid}>
                                        <ListItemText primary={category.label} />
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText className='error'>{errorMessages.category}</FormHelperText>
                    </FormControl>
                    <TextField fullWidth label="Montant"  
                        inputProps={{
                            'data-min':'500',
                            'data-error':"Montant inférieur à 500 euros, la dépense doit donc etre gérée en charge d'exploitation et non en demande d'investissement."
                        }}
                        size="medium"
                        value={localFormValues.price.toString().replace(/\d{3}(?=.)/g, '$& ')}
                        name="price"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.price}
                        helperText={errorMessages.price}
                        disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                        InputProps={{
                            inputComponent: NumericFormatCustom as any,
                        }}/>
                    <FormControl>
                        <InputLabel className={style.selectLabel}>Cotation</InputLabel>
                        <Select
                            required
                            name='roi'
                            size="medium"
                            input={<OutlinedInput label="Cotation" />}
                            value={localFormValues?.roi || ""}
                            onChange={(e) => handleFormInvestChange('roi', e.target.value)}
                            error={!!errorMessages.roi}
                            disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}>
                                {fakeObjectRadioRoi.map((roi : {uid: number;name: string;}) => (
                                    <MenuItem key={roi.uid} value={roi.uid}>
                                        <ListItemText primary={roi.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText className='error'>{errorMessages.roi}</FormHelperText>
                    </FormControl>
                    <FormControl>
                        <InputLabel className={style.selectLabel}>Plaque (pour une demande globale)</InputLabel>
                        <Select
                            name='univers'
                            size="medium"
                            input={<OutlinedInput label="Plaque (pour une demande globale)" />}
                            value={universUid}
                            onChange={(e) => handleFormInvestChange('universUid', e.target.value)}
                            error={!!errorMessages.univers}
                            disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}>
                                <MenuItem key={0} value={""}>
                                    <ListItemText primary={"Aucune"} />
                                </MenuItem>
                                {TOUnivers.map((univers : Univers) => (
                                    <MenuItem key={univers.uid} value={univers.uid}>
                                        <ListItemText primary={univers.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText className='error'>{errorMessages.univers}</FormHelperText>
                    </FormControl>
                    <FormControl >
                        <Autocomplete
                            title={salepointLabel.join(', ')}
                            multiple
                            className={style.autocomplete}
                            limitTags={2}
                            options={TOSalepoint}
                            disableCloseOnSelect
                            disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                            getOptionLabel={(salepoint :Salepoint) => salepoint.name}
                            onChange={handleChangSalepoint}
                            renderOption={(props, salepoint, { selected = salepointLabel.indexOf(salepoint.name) > -1 }) => {
                                const { ...optionProps } = props;
                                return (
                                <li  {...optionProps}>
                                    <Checkbox
                                        icon={icon}
                                        value={salepoint.name}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={salepointLabel.indexOf(salepoint.name) > -1 || selected}
                                        required={(salepointLabel.length === 0)}
                                        />
                                    {salepoint.name}
                                </li>
                                );
                            }}
                            value={TOSalepoint.filter((salepoint:Salepoint)=>{
                                if(salepointLabel.indexOf(salepoint.name) > -1) {
                                    return salepoint 
                                 }
                            })}
                            renderInput={ params => {
                                const { InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;
                                return (
                                  <TextField
                                    required={(salepointLabel.length === 0)}
                                    name='salepoint'
                                    error={!!errorMessages.salepoint}
                                    { ...restParams }
                                    label="Concession(s)"
                                    InputProps={ {
                                      ...restInputProps,
                                      startAdornment: (
                                        <div className={style.scrollDiv}>
                                          {startAdornment}
                                        </div>
                                      ),
                                    } }
                                  />
                                );
                              } }
                            />
                        <FormHelperText className='error'>{errorMessages.salepoint}</FormHelperText>
                    </FormControl>
                    <div className={style.containercheck}>
                        <div className={style.uicheckbox}>
                        <FormGroup sx={{flexDirection: 'column'}}>
                            <FormControlLabel
                                control={<Checkbox name="budget" 
                                                    onChange={(e, checked) => handleFormInvestChange("budget", checked)}
                                                    checked={invest?.budget} 
                                                    disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}/>}
                            label={"Prévu au Budget Annuel"}
                            />
                            <FormHelperText className='error'>{errorMessages.budget}</FormHelperText>
                        </FormGroup>
                        {
                            (invest?.budget) ?
                                <>
                                    <RadioOnly Objet={fakeObjectRadioBudget} 
                                        legend=""
                                        onChange={onChangeRadio}
                                        disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                                        checkedUid={(invest?.budgetType ?? "").toString()}
                                        // errorMessages={errorMessages}
                                        required={true}/>
                                </>
                            :       
                                ""
                        }
                        </div>
                    </div>
                    {
                        (categoryUid === CategoryIT) ?
                            <div className={style.containerIT}>
                               <TextField fullWidth label="Nombre d'ordinateurs/équipement"  
                                    size="medium"
                                    value={localFormValues.userNumber.toString().replace(/\d{3}(?=.)/g, '$& ')}
                                    name="userNumber"
                                    onBlur={handleBlur}
                                    type='number' 
                                    onChange={(e) => handleLocalChange(e)} required
                                    error={!!errorMessages.userNumber}
                                    helperText={errorMessages.userNumber}
                                    disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                                    />
                               <TextField fullWidth label="Nom de la ou des personnes concernées"  
                                    size="medium"
                                    value={localFormValues.userName}
                                    name="userName"
                                    onBlur={handleBlur}
                                    onChange={(e) => handleLocalChange(e)} required
                                    error={!!errorMessages.userName}
                                    helperText={errorMessages.userName}
                                    disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                                    />
                               <TextField fullWidth label="Nom de la/des personnes remplacées (si remplacement)"
                                    size="medium"
                                    value={localFormValues.userNameleave}
                                    name="userNameleave"
                                    onBlur={handleBlur}
                                    onChange={(e) => handleLocalChange(e)}
                                    error={!!errorMessages.userNameleave}
                                    helperText={errorMessages.userNameleave}
                                    disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                                    />
                            </div>
                        :                    
                            ""

                    }
                    {
                    // <div></div> use for align file and preview
                    }
                    <div></div>
                    <div className={style.btnFileContainer}>
                        <Button component="label"
                                role="contained"
                                variant="contained"
                                tabIndex={-1}
                                startIcon={<CloudUploadIcon />}
                                disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                                >
                                    Importer un devis
                                <VisuallyHiddenInput 
                                    type="file"
                                    name='file'
                                    multiple
                                    onChange={(e) => handleFormInvestChange('file', e.target.files)} />
                        </Button>
                        {
                            (TFilePath && TFilePath.length > 0) ? 
                                <>
                                    {(invest?.userUid === user.uid) ? <DeleteIcon onClick={(e) => removeAllFileByInvest(invest?.uid)}  titleAccess='Supprimer ce(s) devis'/> : ""}
                                </>
                            : 
                                ""
                        }
                    </div>
                    {
                        (TFilePath && TFilePath.length > 0) ? 
                            <div className={style.listLink}>
                                {TFilePath.map((path:string, index : number) => <Link className={style.fileLink} to={path} target="_blank">Consulter le devis n°{index +1 }</Link> )}
                            </div>
                        : 
                            <div></div>
                    }
                    {
                        (TOComment.length > 0) ? 
                            <>
                                <h2  className={styleModal.subtitle}>Commentaires :</h2>
                                <div className={style.historyComment}>
                                <FormControl className={style.historyContent}>
                                    <TextField label="Ajouter un commentaire" 
                                        size="small"
                                        value={localFormValues.comment || newComment}
                                        name="comment"
                                        onBlur={handleBlur}
                                        onChange={(e) => handleLocalChange(e)} 
                                        multiline
                                        rows={3}
                                        required
                                        error={!!errorMessages.comment}
                                        helperText={errorMessages.comment}
                                        disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                                    />
                                </FormControl>
                                {TOComment.map((comment : Comment) => (
                                    <FormControl className={style.historyContent}>
                                        <TextField label={comment.user + ' le ' + jsDateToLocalFr(sqlToJsDate(comment.createdAt))}
                                            size="small"
                                            value={comment.comment}
                                            multiline
                                            rows={3}
                                            disabled={true}
                                        />
                                    </FormControl>
                                ))}
                                </div>
                            </>
                        :
                        <TextField label="Ajouter un commentaire" 
                            size="small"
                            value={localFormValues.comment || newComment}
                            name="comment"
                            onBlur={handleBlur}
                            onChange={(e) => handleLocalChange(e)} 
                            multiline
                            rows={4}
                            required
                            error={!!errorMessages.comment}
                            helperText={errorMessages.comment}
                            disabled={((admin && invest?.userUid !== user.uid || disabled) && typeof invest !== "undefined")}
                        />
                            
                    }
                </div>
            </form>)
}
export default FormPopup;